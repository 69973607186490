<template>
        <v-card  height="100%">
            <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
                <div class="pa-3">
                        <v-toolbar>
                        <v-toolbar-title>Dashboard</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-breadcrumbs :items="items"></v-breadcrumbs>
                        </v-toolbar>
                </div>
                <div class="pa-3">
                    <v-row>
                        <template v-for="chart in chartList">
                            <v-col :cols="chart.chart.cols" :sm="chart.chart.sm" :md="chart.chart.md" :key="chart.id">
                                <template v-if="chart.chart.type == 'pie'"> 
                                    <PieChart :chartProp="chart.chart"></PieChart>
                                </template>
                                <template v-if="chart.chart.type == 'bar'"> 
                                    <BarChart :chartProp="chart.chart"></BarChart>
                                </template>
                                <template v-if="chart.chart.type == 'line'"> 
                                    <LineChart :chartProp="chart.chart"></LineChart>
                                </template>
                                <template v-if="chart.chart.type == 'table'"> 
                                    <TableChart :chartProp="chart.chart"></TableChart>
                                </template>                                
                                <template v-if="chart.chart.type == 'box'"> 
                                    <BoxChart :chartProp="chart.chart"></BoxChart>
                                </template>                                  
                            </v-col>
                        </template>
                    </v-row>
                </div>



                <!-- <div class="pa-3">
                    <v-row>
                        <v-col cols="12" sm="6" md="3" class="pa-3" v-for="panel in panel1List" :key="panel.name">
                            <v-card :color="panel.color1">
                                <v-row class="no-gutters">
                                    <v-col cols="1"  :class="panel.color2">
                                    </v-col>
                                    <v-col cols="2" class="pa-2">
                                        <br>
                                        <v-icon x-large color="white">{{panel.icon}}</v-icon>
                                    </v-col>
                                    
                                        <v-col cols="8" class="pa-2 text-right white--text">
                                        {{panel.label}}
                                        <h1 class="white--text text-right">53</h1>
                                    </v-col>                                            
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </div> -->
                
        </v-card>

</template>
<script>
//import axios from 'axios';
import ApDashboardService from "@/services/ApDashboardService"
import DlgMessage from '@/components/DlgMessage'
import BarChart from '@/components/BarChart'
import PieChart from '@/components/PieChart'
//import DonutChart from '@/components/DonutChart'
import LineChart from '@/components/LineChart'
import TableChart from '@/components/TableChart'
import BoxChart from '@/components/BoxChart'

export default {
        components:{
            BarChart,PieChart,LineChart,TableChart,BoxChart,DlgMessage
        },
        data() {
            return{
                //breadcrumb
                form:{dashboard_display_id:1},
                loadingFlag : false,
                errorFlag : false,

                items: [
                    {text:'Dashboard',disabled: false,href:'/dashboard',},
                    ],
                selected: [],
                chartList:[],
                // panel1List:[
                //     {label:"Daily Registration",name:"dailyReg",color1:"green darken-3",color2:"green",icon:"mdi-account-multiple",value:"50"},
                //     {label:"Daily Point Issuance",name:"dailyPts",color1:"blue darken-3",color2:"blue",icon:"mdi-plus-circle-multiple",value:"50"},
                //     {label:"Daily Redemption",name:"dailyRedeem",color1:"indigo darken-3",color2:"indigo",icon:"mdi-gift",value:"50"},
                //     {label:"Daily Used e-Voucher",name:"dailyEV",color1:"orange darken-3",color2:"orange",icon:"mdi-ticket-percent",value:"50"},

                // ],
            //     chartBarRegByTime:{
            //         series: [{
            //                 name: 'data',
            //                 data: [10,20,33,11,21,3,5,6,10,8,5,3,7,22,1,10,10,0,0,0,0,0,0,0]
            //             }],
            //         xcategories: ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"],
            //         dataLabelColors: ["#FF5252"],
            //         barColors: ["#FF5252"],
            //         title:"Member Registration Hourly (20-May-2021)",
            //         yaxisTitle:"Member",
            //     },
            //     chartBarRegByDay:{
            //         series: [{
            //                 name: 'data',
            //                 data: [1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,18,9,10,1,2,3,14,5,6,17,8,9,10,11]
            //             }],
            //         xcategories: ["01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31"],
            //         dataLabelColors: ["#3D5AFE"],
            //         barColors: ["#3D5AFE"],
            //         title:"Daily Member Registration (May-2021)",
            //         yaxisTitle:"Member",
            //     },

            //     chartBarTxAmount:{
            //         series: [{
            //                 name: 'data',
            //                 data: [10,22.50,33.5,41,55,6,7,8,90,100,12,25,31,45.5,50,60,17,18,9,10,1,2,3,4,5,6,7,8,9,100,11]
            //             }],
            //         xcategories: ["01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31"],
            //         dataLabelColors: ["#E65100"],
            //         barColors: ["#E65100"],
            //         title:"Daily Transaction Amount (May-2021)",
            //         yaxisTitle:"Member",
            //     },

            //     chartBarTxPoint:{
            //         series: [{
            //                 name: 'data',
            //                 data: [10,22.50,33.5,41,55,6,7,8,90,100,12,25,31,45.5,50,60,17,18,9,10,1,2,3,4,5,6,7,8,9,100,11]
            //             }],
            //         xcategories: ["01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31"],
            //         dataLabelColors: ["#4CAF50"],
            //         barColors: ["#4CAF50"],
            //         title:"Daily Point Issuance (May-2021)",
            //         yaxisTitle:"Member",
            //     },
            // tblHeadersReg:[
            //     { text:"Date", value:"created_date" },
            //     { text:"Login ID", value:"login_id" },
            // ],
            // tblBodyReg:[{created_date:"2021-05-19",login_id:"testing@testing.com"}],
            // tblHeadersTx:[
            //     { text:"Date", value:"created_date" },
            //     { text:"Point", value:"points" },
            // ],
            // tblBodyTx:[
            //     {created_date:"2021-05-19",points:"50"},
            //     {created_date:"2021-05-19",points:"150"},
            //     {created_date:"2021-05-19",points:"150"},
            //     {created_date:"2021-05-19",points:"520"},
            // ],


            }
        },
        async created() {
            this.refreshDataTable()
            //setTimeout(() => {  this.refreshDataTable() }, 2000);
           
        },
        methods:{
            dlgClose(){
                this.visible = false;
            },

            async refreshDataTable(){
                
                try{
                    ApDashboardService.view_dashboard(this.form).then((res) => {
                        this.chartList = res.data.chartList;
                    }).catch((e)=>{
                        console.log(e.response);
                        this.$refs.dlgMsg.showDlgBox(true,'error','Dashboard',e.response.data.error_message+" Redirecting to login page....");
                        //setTimeout(() => {  this.$router.push('/logout'); }, 3000);
                        
                    })
                    ;
                } catch(e) {
                    this.loadingFlag = false
                    this.$refs.dlgMsg.showDlgBox(true,'error','Dashboard','Unknown Error');
                  
                }           
            },

        }

}
</script>