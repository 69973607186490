import axios from "axios"
let vlogin_id = localStorage.getItem('login_id');
class ApNotificationService {

    
    view_dashboard(form) {
        vlogin_id = localStorage.getItem('login_id');
        //console.log("apdashboardservices local storage",localStorage.getItem('token_auth'));
        axios.defaults.headers.common['Token-Auth'] = localStorage.getItem('token_auth');
        //console.log("axios Token-Auth",axios.defaults.headers.common['Token-Auth']);
        form.login_id = vlogin_id;
        let response = axios.post("ap_dashboard/view_dashboard", form);
        return response;

    }



}

    
 

export default new ApNotificationService();